<template>
  <Authenticated>
    <SingleInput :show="showAddModifier" message="What is the new modifier set name?" primary="Create" @save="create"/>

    <div>
      <button @click="showAddModifier = ! showAddModifier" class="action-buttons"><font-awesome-icon icon="plus" /> Add Modifier Set</button>
      <div class="actions">
        <h2>Modifier Sets</h2>
      </div>

      <ul class="table wrapper layout content-gap">
        <router-link :to="'/modifiers/' + currentSet.value._id.replace(/^.*:/, '')" v-bind:key="currentSet" v-for="currentSet in modifier_sets" class="modifierBox">
        <li>
          <div class="box">
            <p>
              <span class="modifier-name">{{ currentSet.value.name }}</span>
              <span v-if="currentSet.value.modifiers && (currentSet.value.modifiers.length > 0)" class="modifier-price">{{ currentSet.value.modifiers.length }} modifiers</span>
              <span class="tag" v-if="typeof currentSet.value.rules === 'object' && currentSet.value.rules.required">
                Required
              </span>
            </p>
            <span>({{ modifierset_in_product.find(prodmod => prodmod.mod == currentSet.id).products.length }} products use)</span>
          </div>
        </li>
        </router-link>
      </ul>
    </div>

  </Authenticated>
</template>

<script>
import Authenticated from "@/components/_layouts/Authenticated";
import SingleInput from "@/components/_layouts/SingleInput";
import {mapGetters} from "vuex";
import createdMixin from "@/components/_mixins/createdMixin";
import Slug from "slug";

export default  {
  name: 'Modifiers',
  mixins: [createdMixin],
  components: {
    Authenticated,
    SingleInput,
  },

  data() {
    return {
      channel: null,
      currentSet: null,
      currentModifier: null,
      currentDrag: null,
      currentDragOverCategory: null,
      showAddModifier: false,
      tableMode: false
    }
  },
  mounted(){
    this.$store.commit('currPage', 'modifiers')
  },
  methods: {
    create(ms) {
      this.channel.push("document:new", {
        _id: 'modifierset:' + Slug(ms),
        name: ms,
        modifiers: [],
        rules: { inventory: false, oneonly: false, required: false}
      });
    },
    go(id) {
      this.$router.push('/modifiers/' + id.replace(/^.*:/, ""));
    },
    hasModifiers(currentSet) {
      return currentSet.value && currentSet.value.modifiers && currentSet.value.modifiers.length
    }
  },

  computed: {
    ...mapGetters({
      modifier_sets: 'modifier_sets',
      modifierset_in_product: 'modifierset_in_product' 
    })
  }
}
</script>

<style scoped lang="scss">
  @import "public/layout";
  @import "public/actions";
  @import "public/wrapper";
  @import "public/toggle";

  .box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
</style>
